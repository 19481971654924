import { inject } from 'mobx-react'
import React from 'react';

import waveTop from './../img/wavetop.svg'
import waveBottom from './../img/wavebottom.svg'

import FoldPromoFooter from './FoldPromoFooter';
import NotificationBanner from './NotificationBanner'
import SignUpForm from './SignUpForm'
import WalletFooter from './WalletFooter';

@inject('sessionStore')
class About extends React.Component {
  render() {
    return (
      <div>
        <div className="about">
          <div className="main-content">
            <div className="black-row">
              <div className="container">
                <h1>What?</h1>
                <p>Lightning Pizza enables you to order Domino’s Pizza using the Lightning Network at a discount. Every order is {this.props.sessionStore.discountPercentage}% off, &lt;$0.01 transaction fees, instant settlements and ~30min delivery time. Lightning Pizza is the First Nation-wide retail service powered by lightning payments meant to spread utility and spur awareness and adoption. The Lightning Pizza stands on the shoulders of giants, special thanks to LND labs, Satoshi, etc.</p>
              </div>
            </div>
            <div className="blue-row">
              <img className="wave-top" src={waveTop} alt="wave top" />
              <div className="container-wrapper">
                <div className="container">
                  <h1>How?</h1>
                  <ul>
                    <li>Enter order and delivery details.</li>
                    <li>Pay with bitcoin via the Lightning Network.</li>
                    <li>Get status updates on your delivery.</li>
                    <li>Enter tip amount displayed to you when your pizza is delivered</li>
                  </ul>
                  <a className="btn contact-support-btn" href="mailto:support@ln.pizza">Contact Support</a>
                </div>
              </div>
              <img className="wave-bottom" src={waveBottom} alt="wave bottom" />
            </div>
            <WalletFooter/>
            <div className="black-row">
              <div className="container">
                <SignUpForm />
              </div>
            </div>
            <NotificationBanner>
              LN.pizza was built with <a href="https://github.com/lightningnetwork/lnd" target="_blank" rel="noopener noreferrer">LND</a>
            </NotificationBanner>
            <FoldPromoFooter/>
          </div>
        </div>
      </div>
    )
  }
}

export default About;
