import { configure } from 'mobx'
import { create } from 'mobx-persist'
import { Provider } from 'mobx-react'
import { RouterStore } from 'mobx-react-router'
import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import About from './components/About'
import App from './components/App'
import Contact from './components/Contact'
import Downtime from './components/Downtime';
import Home from './components/Home'
import Order from './components/Order'
import Products from './components/Products'
import StoreLocator from './components/StoreLocator'
import StoreResults from './components/StoreResults'
import * as appStores from './stores'

import './app.css'


configure({ enforceActions: 'observed' })

const routingStore = new RouterStore()

const hydrate = create({
  storage: window.localStorage,
  jsonify: true
})

Object.values(appStores).forEach((store) => {
  hydrate(`LNPizza:${store.name}`, store)
})

const stores = {
  routing: routingStore,
  ...appStores
}

class Root extends Component {
  render() {
    return (
      <Provider {...stores}>
        <Router>
          <App>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/locations/:service" component={StoreLocator} />
            <Route exact path="/locations/:service/stores" component={StoreResults} />
            <Route exact path="/order/:orderID/contact" component={Contact} />
            <Route exact path="/order/:orderID/products" component={Products} />
            <Route exact path="/order/:orderID" component={Order} />
            <Route exact path="/downtime" component={Downtime} />
          </App>
        </Router>
      </Provider>
    )
  }
}

export default Root
