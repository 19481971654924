import { inject, observer } from 'mobx-react'
import React from 'react'
import QRCode from 'qrcode.react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import CopyToClipboardButton from './CopyToClipboardButton'
import { Lightning } from './Icons'
import Receipt from './Receipt'
import Panel, { PanelHeader } from './Panel'

const padLeft = (x, n, z) => {
  x = String(x)
  while (x.length < n) {
      x = z + x
  }
  return x
}

const formatMillis = (millis) => {
  if (!millis) {
    return '0:00'
  }
  const s = Math.ceil(millis / 1000)
  return Math.floor(s / 60) + ':' + padLeft(s % 60, 2, '0')
}

@inject('locationsStore', 'orderStore', 'userFormStore', 'sessionStore')
@observer
class Order extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      orderID: props.match.params.orderID,
      interval: null,
      millisRemaining: 1200000,
    }
  }

  componentDidMount() {
    this.startTimer()
  }

  componentWillUnmount() {
      this.stopTimer()
  }

  startTimer = () => {
    window.clearInterval(this.state.interval)
    this.setState({
        interval: window.setInterval(() => {
            this.setState(state => {
              const newMillisRemaining = state.millisRemaining - 1000
              state.millisRemaining = newMillisRemaining >= 1000 ? newMillisRemaining : 0
              return state
            })
        }, 1000)
    })
  }

  stopTimer = () => {
      window.clearInterval(this.state.interval)
      this.setState({ interval: null })
  }

  onTimerExpired = () => {
    // We can do something here.
  }

  getMailToLink() {
    const subject =
      encodeURI(`Order Failed: ${this.state.orderID}`)

    return `mailto:support@ln.pizza&subject=${subject}`
  }

  getServiceTitle() {
    const { details: orderDetails } = this.props.orderStore

    if (orderDetails.service === 'carryout') {
      return 'Pickup at:'
    } else {
      return `Delivering to ${orderDetails.first_name} ${orderDetails.last_name}`
    }
  }

  getServiceAddress() {
    const { details: orderDetails } = this.props.orderStore

    if (orderDetails.service === 'carryout') {
      return this.getStoreAddress()
    } else {
      return <>
        {orderDetails.address} {orderDetails.apt_number || ''}<br/>
        {orderDetails.city}, {orderDetails.state} {orderDetails.zip_code}
      </>
    }
  }

  getStoreAddress() {
    const { details: orderDetails } = this.props.orderStore
    const { locations } = this.props.locationsStore

    const store = locations.find(
      loc => Number(loc.StoreID) === Number(orderDetails.store_id)
    )
    return store && store.AddressDescription
  }

  handlePayWithBreez = () => {
    window.postMessage(this.props.orderStore.details.paymentRequest, '*')
  }

  renderInvoiceCreatedView() {
    const { details: orderDetails } = this.props.orderStore
    const isBreez = window.localStorage.getItem('breez_wallet')

    return (
      <Panel className="invoice-created">
        <PanelHeader showBackBtn={false}>
          <span className="subtotal">${orderDetails.price_usd}</span>
        </PanelHeader>
        <div className="summary">
          To: {Object.values(this.props.locationsStore.normalizedAddress).join(' ')}
        </div>
        { !isBreez ? (
          <div className="qr-wrapper text-center">
            <QRCode value={orderDetails.paymentRequest} level="L" renderAs="svg" />
          </div>
        ) : ''}
        <div className="timer-container">
          <div className="timer">{ formatMillis(this.state.millisRemaining) }</div>
          <div className="timer-label">Redemption Time</div>
        </div>
        { !isBreez ? (
            <>
              <div className="input-group mb-3">
              <div className="input-group-prepend">
                <a className="payment-link" href={"lightning:" + orderDetails.paymentRequest}><span className="input-group-text"><span role="img" aria-label="lightning">⚡</span> {orderDetails.price_satoshis.toLocaleString()} sat</span></a>
              </div>
              <input id="paymentRequest" type="text" className="form-control" aria-label="Payment Request" value={orderDetails.paymentRequest} readOnly></input>
              <div className="input-group-append">
                <CopyToClipboard text={orderDetails.paymentRequest}>
                  <button id="paymentRequestBtn" className="btn btn-primary" type="button">Copy</button>
                </CopyToClipboard>
              </div>
            </div>
            <CopyToClipboard text={orderDetails.node_uri}>
              <p className="peer-address text-center">Peer: <CopyToClipboard text={this.props.sessionStore.nodeUri}><span>{this.props.sessionStore.nodeUri}</span></CopyToClipboard></p>
            </CopyToClipboard>
            </>
        ) : (
          <button id="payNowBtn"
            className="pay-now-btn btn btn-primary btn-yellow"
            onClick={this.handlePayWithBreez}>
            <Lightning />
            Pay Now
            <Lightning flip />
          </button>
        )}
      </Panel>
    )
  }

  renderMessage(message) {
      return (
        <Panel className="message">
          <PanelHeader showBackBtn={false} />
          {message}
        </Panel>
      );
  }

  renderOrderSuccess() {
    const { discountPercentage } = this.props.sessionStore
    const { details: orderDetails } = this.props.orderStore

      return (
        <Panel className="order-success">
          <PanelHeader showBackBtn={false}>
            <h2>Order Complete</h2>
          </PanelHeader>
          <h2 className="total-heading">
            <Lightning width="16" height="30" />
            <span>${orderDetails.price_usd}</span>
            <Lightning width="16" height="30" flip />
          </h2>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <Receipt
                orderDetails={orderDetails}
                discountPercentage={orderDetails.discount_percentage || discountPercentage}
                tipAsterisk={orderDetails.service === 'delivery'} />
            </div>
            <div className="col-sm-12 col-md-6 tracking-info">
              <span>{this.getServiceTitle()}</span>
              <address>{this.getServiceAddress()}</address>
              <div>
                <a href={"https://www.dominos.com/en/pages/tracker/?uph=" + orderDetails.phone} target="_blank" rel="noopener noreferrer">Track your order here</a>
              </div>
            </div>
          </div>
          { orderDetails.tip_usd > 0 &&
            <div className="add-tip">
              <div className="big-text">
                *add this tip <span className="tip-amount">${ orderDetails.tip_usd }</span>
              </div>
              <div className="small-text">
                Write this exact tip amount on your driver&#39;s receipt
              </div>
            </div>
          }
        </Panel>
      )
  }

  renderOrderFailure() {
    return(
      <Panel className="order-failure">
        <PanelHeader showBackBtn={false} />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h2 className="h1">oh no, we could'nt complete your order!</h2>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="copy-field-wrapper">
              <h3>Order ID</h3>
              <CopyToClipboardButton
                className="order-id-btn"
                copyText={this.state.orderID} />
            </div>
            <h5>Please contact us to receive a refund.</h5>
            <div className="contact-us">
                <a href={this.getMailToLink()}>support@ln.pizza</a>
            </div>
          </div>
        </div>
      </Panel>
    )
  }

  renderInvoiceExpiredView() {
    return(
      <Panel className="invoice-expired">
        <PanelHeader showBackBtn={false} />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h2 className="h1">Uh oh, your order expired!</h2>
          </div>
          <div className="col-sm-12 col-md-6">
            <h5>You can still get pizza, you just have to start over.</h5>
            <div className="contact-us">
                <a href='/'>TRY AGAIN</a>
            </div>
          </div>
        </div>
      </Panel>
    )
  }

  renderView() {
    switch(this.props.orderStore.details.status) {
      case 'invoice_created':
        return this.renderInvoiceCreatedView();
      case 'invoice_paid':
        return this.renderMessage("Payment received, placing order. Please wait a moment.");
      case 'invoice_expired':
        return this.renderInvoiceExpiredView();
      case 'order_pending':
        return this.renderMessage("Payment received, placing order. Please wait a moment.");
      case 'order_success':
        return this.renderOrderSuccess();
      case 'order_failure':
        return this.renderOrderFailure()
      default:
        return <></>;
    }
  }

  render() {
    return (
      <div className="order">
        <div className="container">
          { this.renderView() }
        </div>
      </div>
    )
  }
}

export default Order;
