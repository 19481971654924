import { inject, observer } from 'mobx-react'
import React from 'react'

import { formatUSD, formatSAT } from '../utils'

import CustomPizzaBuilder from './CustomPizzaBuilder'
import Panel, { PanelHeader } from './Panel'
import ProductCard from './ProductCard'


@inject('menuStore', 'orderStore', 'sessionStore')
@observer
class Products extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      orderID: props.match.params.orderID
    }
  }

  componentDidMount() {
    const { menuStore, orderStore, sessionStore } = this.props

    sessionStore.fetchSession()

    orderStore.fetchOrder(this.state.orderID, (data) => {
      menuStore.fetchMenu(data.store_id)
    })
    orderStore.subscribeToPusherEvents(this.state.orderID)
  }

  completeProductSelection = () => {
    const { orderStore, history } = this.props
    const { orderID } = this.state

    orderStore.updateOrder(orderID, orderStore.details, () => {
      history.push(`/order/${orderID}/contact`)
    })
  }

  handleProductClick = (product, change) => {
    this.props.orderStore.updateProducts(product, change)
  }

  handleAddCustomPizza = (data) => {
    this.props.orderStore.addCustomPizza(data)
  }

  getQuantity(product) {
    const products = this.props.orderStore.details.products || []
    const match = products.find(p => p.uuid === product.uuid)

    return (match && match.quantity) || 0
  }

  render() {
    const { prebuilt, toppings } = this.props.menuStore
    const { details: orderDetails, numSelectedProducts,
      subtotal } = this.props.orderStore

    const customPizzas = (orderDetails.products || [])
      .filter(p => p.category === 'custom')
    const pizzas = prebuilt.filter(p => p.category === 'pizza')
    const sides = prebuilt.filter(p => p.category === 'side')

    return (
      <div className="container page-container products-view">
        <Panel className="choose-products" sticky>
          <PanelHeader onBackClick={this.props.history.goBack}>
            <h2>Choose your pizza</h2>
          </PanelHeader>
            <div className="products">
              <form className="panel-body">
                <div className="container">
                  <div className="row">
                    <CustomPizzaBuilder
                      toppings={Object.keys(toppings).map(k => ({
                        key: k,
                        ...toppings[k]
                      }))}
                      onAddPizzaClick={this.handleAddCustomPizza} />
                  </div>
                  <div className="row">
                  {customPizzas.map((product, idx) => (
                    <ProductCard
                      key={product.uuid}
                      custom={true}
                      product={product}
                      quantity={product.quantity}
                      disabledMinus={!product.quantity}
                      onClick={this.handleProductClick} />
                  ))}
                  {pizzas.map(product => {
                    const quantity = this.getQuantity(product)
                    return <ProductCard
                      key={product.uuid}
                      product={product}
                      quantity={quantity}
                      disabledMinus={!quantity}
                      onClick={this.handleProductClick} />
                  })}
                  </div>
                  <div className="row">
                    <div className="panel-header">
                      <h2 className="choose-your-sides">Choose Your Sides</h2>
                    </div>
                    {sides.map(product => {
                      const quantity = this.getQuantity(product)
                      return <ProductCard
                        key={product.uuid}
                        product={product}
                        quantity={quantity}
                        disabledMinus={!quantity}
                        onClick={this.handleProductClick} />
                    })}
                  </div>
                </div>
              </form>
              <div className="panel-footer">
                { subtotal > 0 ?
                  <div className="subtotal">
                    <div className="usd">{ formatUSD(subtotal) }</div>
                    <div className="satoshis">
                      { formatSAT(subtotal / this.props.sessionStore.usdPerSatoshi) }
                    </div>
                  </div> : ''
                }
                <button className="btn"
                  disabled={numSelectedProducts < orderDetails.minimum_products}
                  onClick={this.completeProductSelection}>
                  { numSelectedProducts < orderDetails.minimum_products ?
                    `Minimum ${orderDetails.minimum_products} items` :
                    `Buy ${numSelectedProducts} items`
                  }
                </button>
                { this.props.orderStore.error && this.props.orderStore.error.message &&
                  <div className="error-message">
                    { this.props.orderStore.error.message }
                  </div>
                }
              </div>
            </div>
        </Panel>
      </div>
    )
  }
}

export default Products
