import { action, computed, observable } from 'mobx'
import { persist } from 'mobx-persist'

import FormStore from './FormStore'


export class LocationFormStore extends FormStore {

  name = 'LocationFormStore'

  @persist
  @observable
  isDelivery = false

  @persist('object')
  @observable
  fields = {
    address: {
      id: 'address',
      name: 'Address',
      type: 'text',
      value: '',
      error: null
    },
    apt_number: {
      id: 'apt_number',
      name: 'Apt',
      type: 'text',
      value: '',
      error: null,
      optional: true
    },
    city: {
      id: 'city',
      name: 'City',
      type: 'text',
      value: '',
      error: null
    },
    state: {
      id: 'state',
      name: 'State',
      type: 'text',
      value: '',
      error: null
    },
    zip_code: {
      id: 'zip_code',
      name: 'Zip Code',
      type: 'zipCode',
      value: '',
      error: null
    }
  }

  @persist
  @observable
  error = null

  @computed
  get displayFields() {
    return this.isDelivery ? this.fields : { zip_code: this.fields.zip_code }
  }

  @action.bound
  setDelivery(val) {
    this.isDelivery = val
  }
}

export default new LocationFormStore()
