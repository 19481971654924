import React from 'react'

import { Facebook, Instagram, LinkedIn, Twitter } from './Icons'
import foldLogo from '../img/F_logomark_black.svg'


const FoldPromoFooter = () => (
  <div className="fold-promo-footer">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-9">
          <img className="fold-logo" alt="Fold Logo" src={foldLogo}></img>
          <h3>Spend and earn crypto at Starbucks, Uber, Target and Amazon with <a href="https://foldapp.com" target="_blank" rel="noopener noreferrer">Fold</a>.</h3>
          <ul className="links">
            <li>
              <a href="https://www.instagram.com/foldapp/" target="_blank"
                rel="noopener noreferrer">
                <Instagram width="30" height="30" />
                <span>Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/fold_app" target="_blank"
                rel="noopener noreferrer">
                <Twitter />
                <span>Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/FoldBitcoin/" target="_blank"
                rel="noopener noreferrer">
                <Facebook width="30" height="30" />
                <span>Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/card-for-coin"
                target="_blank" rel="noopener noreferrer">
                <LinkedIn />
                <span>LinkedIn</span>
              </a>
            </li>
          </ul>
          <p>All trademarks not owned by Fold that appear on our website are the property of their respective owners. Fold is not the issuer of any of the gift cards or other closed-loop products sold on Fold and is not related to any merchant whose trademark and gift cards appear on Fold for sale.</p>
        </div>
      </div>
    </div>
  </div>
)

export default FoldPromoFooter
