import { action, observable, flow } from 'mobx'
import { persist } from 'mobx-persist'

import { getMenu } from './../api'

import { formatError } from './utils'


export class MenuStore {

  name = 'MenuStore'

  @observable isLoading = false
  @persist('list') @observable prebuilt = []
  @persist('object') @observable toppings = {}
  @observable error = null

  fetchMenu = flow(function* (storeId, onSuccess = () => {}) {
    this.prebuilt = []
    this.toppings = {}
    this.isLoading = true
    this.error = null

    try {
      const response = yield getMenu(storeId)
      this.prebuilt = response.data.prebuilt
      this.toppings = response.data.toppings
      this.isLoading = false
      onSuccess()
    } catch (error) {
      this.error = formatError(error)
      this.isLoading = false
    }
  })

  @action
  reset() {
    this.isLoading = false
    this.prebuilt = []
    this.toppings = {}
    this.error = null
  }

}

export default new MenuStore()
