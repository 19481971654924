import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransition } from 'react-transition-group'


export const Animation = ({ name, children, visible, appear, onExited, animateTimeout }) => (
  <CSSTransition timeout={animateTimeout} classNames={name} in={visible}
    appear={appear} onExited={onExited}>
    <div className={name}>
      { children }
    </div>
  </CSSTransition>
)

Animation.propTypes = {
  animateTimeout: PropTypes.number,
  appear: PropTypes.bool,
  visible: PropTypes.bool,
  onExited: PropTypes.func
}

Animation.defaultProps = {
  animateTimeout: 1000,
  appear: true,
  visible: true,
  onExited: () => {}
}

export default Animation
