import React from 'react'
import classNames from 'classnames'

import { submitEmail } from '../api'


class SignUpForm extends React.Component {
  state = {
    email: null,
    submitting: false,
    submitSuccess: false,
    submitError: ''
  }

  handleInput = evt => {
    this.setState({
      email: evt.target.value,
      submitError: ''
    })
  }

  handleSubmit = evt => {
    evt.preventDefault()
    submitEmail(this.state.email)
      .then(res => {
        if (res.data.success) {
          this.setState({ submitSuccess: true })
        } else {
          this.setState({
            submitError: "Invalid Email Address",
            submitting: false
          })
        }
      })
      .catch(err => {
        this.setState({
          submitError: err.message,
          submitting: false
        })
      })

    this.setState({ submitting: true })
  }

  render() {
    return (
      <div className={classNames('signup-form', this.props.className)}>
        <div className="signup-email">
          <div className="signup-label">
            Sign up for updates:
          </div>
          <input className="signup-input"
            placeholder="you@yourcompany.com"
            onChange={this.handleInput} />
          {this.state.submitError
            ? <div className="error">{this.state.submitError}</div>
            : ''
          }
        </div>
        {this.state.submitSuccess
          ? <div className="got-it">Got it!</div>
          : <button className="signup-button btn btn-primary"
              onClick={this.handleSubmit}
              disabled={this.state.submitting}>Send</button>
        }
      </div>
    )
  }
}

export default SignUpForm

