export function formatError(error) {
  error = error || {}

  const DEFAULT_MESSAGE =
    'Sorry, we couldn\'t complete the request. Please try again.'

  let errorMessage = DEFAULT_MESSAGE

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message
  } else if (error.response && error.response.status && error.message) {
    switch (error.response.status) {
      case 401:
        errorMessage = 'Sorry, that request was unauthorized.'
        break
      case 403:
        errorMessage = 'Sorry, you do not have permission for that request.'
        break
      case 500:
        errorMessage = DEFAULT_MESSAGE
        break
      default:
        errorMessage = error.message || DEFAULT_MESSAGE
    }
  }

  return {
    code: (error.response && error.response.status) || null,
    message: errorMessage
  }
}
