import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'


export function getSession() {
  return axios.get('/api/session')
}

export function getLocations(data) {
  return axios.post('/api/locations', data)
}

export function createOrder(data) {
  return axios.post('/api/order', data)
}

export function getOrder(orderId) {
  return axios.get(`/api/order/${orderId}`)
}

export function updateOrder(orderId, orderDetails) {
  return axios.put(`/api/order/${orderId}`, orderDetails)
}

export function submitEmail(email) {
  return axios.post('/api/subscribe', { email })
}

export function getMenu(storeId) {
  return axios.get(`/api/menu/${storeId}`)
}
