import React from 'react'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'


class CouponForm extends React.Component {
  static propTypes = {
    coupon: PropTypes.string,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    error: PropTypes.string,
    success: PropTypes.bool,
    isLoading: PropTypes.bool
  }

  state = {
    inputError: ''
  }

  handleInput = evt => {
    this.setState({
      inputError: ''
    })
    this.props.onChange(evt.target.value)
  }

  handleSubmit = evt => {
    evt.preventDefault()

    const { coupon } = this.props

    if (!coupon || !(/^[a-z0-9]+$/i.test(coupon))) {
      this.setState({
        inputError: 'Please enter a valid code'
      })
    } else {
      this.props.onSubmit(coupon)
    }
  }

  render() {
    const { error: serverError, isLoading, success, coupon } = this.props
    const { inputError } = this.state
    return (
      <div className="form-row coupon-form">
        <div className="col-auto">
          <label className="sr-only" htmlFor="couponCode">Discount code</label>
          <input className="coupon-input form-control"
            id="couponCode"
            placeholder="Enter discount code"
            onChange={this.handleInput}
            value={coupon} />
          {inputError || serverError ? (
              <div className="error">
                LN Pizza codes only. {inputError || serverError}.
              </div>
            ) : ''
          }
          {success
            ? <div className="success">Applied!</div>
            : ''
          }
        </div>
        <div className="col-auto">
          <button className={`apply-coupon-button btn ${isLoading ? 'loading' : ''}`}
            onClick={this.handleSubmit}
            disabled={isLoading}>
            { isLoading ? (
                <ReactLoading type="bubbles" height={36} width={36} />
              ) : 'Apply'}
          </button>
        </div>
      </div>
    )
  }
}

export default CouponForm

