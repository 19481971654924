import classNames from 'classnames'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import { matchPath } from 'react-router'
import { Switch, withRouter } from 'react-router-dom'


import Header from './Header'
import NotificationBanner from './NotificationBanner'

@inject('orderStore', 'sessionStore')
@withRouter
@observer
class App extends Component {
  componentDidMount() {
    const { orderStore, sessionStore, location } = this.props

    sessionStore.fetchSession()

    const match = matchPath(location.pathname, {
      path: '/order/:orderID'
    })

    // Subscribe to pusher if orderId is in url, in case of refresh
    if (match && match.params.orderID) {
      orderStore.fetchOrder(match.params.orderID)
      orderStore.subscribeToPusherEvents(match.params.orderID)
    }
  }

  render() {
    const { pathname } = this.props.location
    const { discountPercentage } = this.props.sessionStore

    return (
      <div className={classNames(
        `route-${pathname === '/' ? 'home' : pathname.slice(1).replace(/\//g, '-')}`,
        {'embedded-web-view': window.localStorage.getItem('breez_wallet')}
      )}>
        {
          discountPercentage && pathname === '/' ? (
            <NotificationBanner>
              {discountPercentage}% off all purchases
            </NotificationBanner>
          ) : ''
        }
        <Header />
        <Switch>
          { this.props.children }
        </Switch>
      </div>
    )
  }
}

export default App
