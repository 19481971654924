import { action, observable, flow } from 'mobx'
import { persist } from 'mobx-persist'

import { getLocations } from './../api'

import { formatError } from './utils'


export class LocationsStore {

  name = 'LocationsStore'

  @observable isLoading = false
  @persist('list') @observable locations = []
  @persist('object') @observable normalizedAddress = {}
  @observable error = null

  fetchLocations = flow(function* (payload, onSuccess = () => {}) {
    this.locations = []
    this.normalizedAddress = {}
    this.isLoading = true
    this.error = null

    try {
      const response = yield getLocations(payload)
      this.locations = response.data.locations
      this.normalizedAddress = response.data.address
      this.isLoading = false
      onSuccess()
    } catch (error) {
      this.error = formatError(error)
      this.isLoading = false
    }
  })

  @action
  reset() {
    this.isLoading = false
    this.locations = []
    this.normalizedAddress = {}
    this.error = null
  }

}

export default new LocationsStore()
