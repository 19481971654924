import React, { Component } from 'react'
import classNames from 'classnames'

class Nav extends Component {
  state = {
    isOpen: false
  }

  handleHamburgerClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }))
  }

  render() {
    const { isOpen } = this.state

    return (
      <div className="nav">
        <nav className={classNames('navbar', 'navbar-expand-lg',
          { 'open': isOpen })}>
          <ul className={classNames('navbar-nav', { 'open': isOpen })}>
            <li className="nav-item">
              <a className="nav-link" href="/about">About</a>
            </li>
          </ul>
        </nav>
        <button className={classNames('hamburger', { 'open': isOpen })}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.handleHamburgerClick}>
        </button>
      </div>
    )
  }
}

export default Nav
