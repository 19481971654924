import { inject } from 'mobx-react'
import React from 'react'

import CopyToClipboardButton from './CopyToClipboardButton'

@inject('sessionStore')
class WalletFooter extends React.Component {
  render() {
    return (
      <div className="wallet-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h1>Need a Wallet?</h1>
              <ul>
                <li><a href="https://zap.jackmallers.com/" target="_blank" rel="noopener noreferrer">Zap</a> (Desktop & iOS)</li>
                <li><a href="https://github.com/lightninglabs/lightning-app/releases" target="_blank" rel="noopener noreferrer">lightning-app</a> (Desktop)</li>
                <li><a href="https://keys.casa/lightning-bitcoin-node/" target="_blank" rel="noopener noreferrer">Casa</a> (Desktop & Casa Node)</li>
                <li><a href="https://bluewallet.io/" target="_blank" rel="noopener noreferrer">Bluewallet</a> (iOS & Android)</li>
                <li><a href="https://play.google.com/store/apps/details?id=fr.acinq.eclair.wallet.mainnet2" target="_blank" rel="noopener noreferrer">Eclair</a> (Android)</li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6">
              <h1 className="label">Connect to our node</h1>
              <CopyToClipboardButton
                className="lightning-node-uri-btn"
                copyText={this.props.sessionStore.nodeUri} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WalletFooter;
