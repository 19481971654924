import { inject, observer } from 'mobx-react'
import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import Animation from './Animation'
import FoldPromoFooter from './FoldPromoFooter';
import WalletFooter from './WalletFooter';
import Panel from './Panel';


@inject(
  'locationFormStore',
  'locationsStore',
  'orderStore',
  'sessionStore',
  'userFormStore'
)
@observer
class Home extends React.Component {
  state = {
    selectedService: 'carryout'
  }

  resetStores = () => {
    this.props.locationFormStore.reset()
    this.props.locationsStore.reset()
    this.props.orderStore.reset()
    this.props.userFormStore.reset()
  }

  handleOptionChange = (e) => {
    this.setState({
      selectedService: e.target.value
    })
  }

  render() {
    return (
      <div className="home">
        <div className="hero">
          <div className="container">
            <Animation name="fade-in-up-elastic">
              <h1><span>Lightning</span> fast delivery, lol</h1>
              <p className="subtitle">Order Domino&#39;s Pizza over the <span className="yellow">Lightning Network</span> from anywhere in the U.S.</p>
            </Animation>
          </div>
        </div>
        <div className="main-content">
          <div className="wave"></div>
          <div className="content">
            <div className="container">
              <Animation name="fade-in-up" animateTimeout={1350}>
                <Panel>
                  <div className="logo-wrapper text-center">
                    <h1>LN Pizza</h1>
                    <p>Made with ♥ by <a href="https://foldapp.com" target="_blank" rel="noopener noreferrer">Fold</a></p>
                  </div>
                  <div className="service-selector">
                    <div className={classNames('carryout-option',
                      { 'selected': this.state.selectedService === 'carryout' })}>
                      <input className="form-check-input"
                        type="radio"
                        name="carryout"
                        id="carryout"
                        value="carryout"
                        onChange={this.handleOptionChange}
                        checked={this.state.selectedService === 'carryout'}/>
                      <label className="form-check-label" htmlFor="carryout">
                        Carryout
                      </label>
                    </div>
                    <div className={classNames('delivery-option',
                      { 'selected': this.state.selectedService === 'delivery' })}>
                      <input className="form-check-input"
                        type="radio"
                        name="delivery"
                        id="delivery"
                        value="delivery"
                        onChange={this.handleOptionChange}
                        checked={this.state.selectedService === 'delivery'}/>
                      <label className="form-check-label" htmlFor="delivery">
                        Delivery
                      </label>
                    </div>
                  </div>
                  <Link
                    to={`/locations/${this.state.selectedService}`}
                    role="button"
                    className="btn btn-primary btn-lg btn-block"
                    onClick={this.resetStores}>
                    Find the nearest Dominos
                  </Link>
                  {
                    this.state.selectedService === 'delivery'
                    ? <div className="disclaimer text-center">
                        * Carryout is recommended if you don't want to expose your address to Domino's Pizza.
                      </div>
                    : ''
                  }
                </Panel>
              </Animation>
            </div>
          </div>
        </div>
        <WalletFooter/>
        <FoldPromoFooter/>
      </div>
    )
  }
}

export default Home;
