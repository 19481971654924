import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { ArrowLeft, Close } from './Icons'


export const PanelHeader = ({ children, onBackClick, showBackBtn }) => (
  <div className="panel-header">
    <button className={classNames('back-btn', { 'hidden': !showBackBtn })}
      onClick={onBackClick}>
      <ArrowLeft />
    </button>
    <div className="content">
      { children }
    </div>
    <Link to="/" className="close-btn">
      <Close />
    </Link>
  </div>
)

PanelHeader.propTypes = {
  onBackClick: PropTypes.func,
  showBackBtn: PropTypes.bool
}

PanelHeader.defaultProps = {
  onBackClick: () => {},
  showBackBtn: true
}


const Panel = ({ children, className, sticky }) => (
  <div className={classNames('panel', { 'sticky': sticky }, className)}>
    { children }
  </div>
)

Panel.propTypes = {
  className: PropTypes.string
}

export default Panel
