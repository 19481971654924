import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'


class TextField extends React.Component {
  getMaxLength() {
    switch (this.props.type) {
      case 'zipCode':
        return 5
      default:
        return this.props.maxLength || 200
    }
  }

  handleChange = (e) => {
    this.props.onChange(e.target.name, e.target.value)
  }

  render() {
    const { className, id, name, value, optional, error } = this.props

    return (
      <div className={classNames('form-group', className)}>
        <label htmlFor={id}>{name}{optional && " (Optional)"}</label>
        <input
          type="text"
          className={classNames('form-control', {
            'is-valid': value && !error && !optional,
            'is-invalid': error && !optional
          })}
          name={id}
          maxLength={this.getMaxLength()}
          onChange={this.handleChange}
          value={value} />
        { error ? (
          <div className="invalid-feedback">
            { error }
          </div>
        ) : '' }
      </div>
    )
  }
}

TextField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  optional: PropTypes.bool,
  onChange: PropTypes.func
}

export default TextField
