import React from 'react'


const NotificationBanner = ({ children }) => (
  <div className="notification-banner">
    <div className="content">
      { children }
    </div>
  </div>
)

export default NotificationBanner
