import React from 'react'

import Nav from './Nav';

const Header = () => (
  <header>
    <div className="container">
      <a className="navbar-brand" href="/">LN Pizza</a>
      <Nav />
    </div>
  </header>
)

export default Header;
