import { action, observable, flow } from 'mobx'
import { persist } from 'mobx-persist'

import { getSession } from './../api'

import { formatError } from './utils'


export class SessionStore {

  name = 'SessionStore'

  @observable isLoading = false
  @persist @observable discountPercentage = null
  @persist @observable nodeUri = null
  @persist @observable usdPerSatoshi = null
  @observable error = null

  fetchSession = flow(function* (onSuccess = () => {}) {
    this.isLoading = true
    this.error = null

    try {
      const response = yield getSession()
      this.discountPercentage = response.data.config.discount_percentage
      this.nodeUri = response.data.config.node_uri
      this.usdPerSatoshi = response.data.config.usd_per_satoshi
      this.isLoading = false
      onSuccess()
    } catch (error) {
      this.error = formatError(error)
      this.isLoading = false
    }
  })

  @action
  reset() {
    this.isLoading = false
    this.discountPercentage = null
    this.nodeUri = null
    this.usdPerSatoshi = null
    this.error = null
  }

}

export default new SessionStore()
