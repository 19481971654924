import React, { Component } from 'react'

class SelectField extends Component {
    handleChange = evt => {
        this.props.onChange(this.props.id, evt.target.value)
    }

    render() {
        const { id, name, value, options } = this.props

        return (
            <div className="form-group">
                <label htmlFor={id}>{name}</label>
                <select className="form-control"
                    id={id}
                    onChange={this.handleChange}
                    value={value} >
                    {options.map(option => {
                        let key, value;
                        for (let k in option) {
                            key = k
                            value = option[k]
                        }
    
                        return <option value={key} key={key}>{value}</option>
                    })}
                </select>
            </div>
        )
    }
}

export default SelectField