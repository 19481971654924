import { inject, observer } from 'mobx-react'
import React from 'react'

import Panel, { PanelHeader } from './Panel'
import TextField from './TextField'


@inject('locationFormStore')
@inject('locationsStore')
@observer
class StoreLocator extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      params: {
        service: props.match.params.service,
      },
      formSubmittedOnce: false
    }
  }

  componentDidMount() {
    if (this.state.params.service === 'delivery') {
      this.props.locationFormStore.setDelivery(true)
    }
  }

  goToResults() {
    this.props.history.push(`/locations/${this.state.params.service}/stores`)
  }

  getLocations = (event) => {
    event.preventDefault()

    const { locationsStore, locationFormStore: formStore } = this.props

    this.setState({ formSubmittedOnce: true })

    if (formStore.isValid) {

      const payload = {
        ...this.state.params,
        ...formStore.formData
      }

      locationsStore.fetchLocations(payload,
        this.goToResults.bind(this))
    }
  }

  submitDisabled() {
    const { locationFormStore, locationsStore } = this.props
    if (locationsStore.isLoading) {
      return true
    }
    if (!locationFormStore.isValid) {
      return true
    }
    if (this.state.formSubmittedOnce && !locationFormStore.isValid) {
      return true
    }
    return false
  }

  render() {
    const { displayFields, onFieldChange } = this.props.locationFormStore
    const { error } = this.props.locationsStore
    return (
      <div className="container page-container">
        <Panel className="enter-your-location">
          <PanelHeader onBackClick={this.props.history.goBack}>
            <h2>Enter your location</h2>
          </PanelHeader>
          <form onSubmit={this.getLocations}>
            <div>
              <div className="form-row">
                {
                  Object.values(displayFields).map((field, idx) => {
                    return <TextField
                      key={idx}
                      id={field.id}
                      name={field.name}
                      error={field.error}
                      value={field.value}
                      optional={field.optional}
                      className="col-md-3"
                      onChange={onFieldChange} />
                  })
                }
              </div>
              <div className="panel-footer form-row">
                <button type="submit"
                  className="btn"
                  disabled={this.submitDisabled()}>
                  Continue
                </button>
                { error && error.message &&
                  <div className="error-message">
                    { error.message }
                  </div>
                }
                <div className="disclaimer">
                  * Data Disclaimer: Lightning Pizza only collects the information necessary for Domino’s Pizza to fulfill your carryout and/or delivery orders. All information, including address, name, phone number and order details will be deleted after the order has been closed-out by Dominos. Emails, however, will be saved to send order updates and promotions.
                </div>
              </div>
            </div>
          </form>
        </Panel>
      </div>
    )
  }
}

export default StoreLocator
