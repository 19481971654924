import _startCase from 'lodash/startCase'
import { action, computed, flow, observable } from 'mobx'
import { persist } from 'mobx-persist'
import Pusher from 'pusher-js'
import uuidv1 from 'uuid/v1'


import { getOrder, createOrder as postOrder, updateOrder as putOrder } from
  './../api'

import { formatError } from './utils'


// TODO: Move this to a config file
const pusher = new Pusher('efd46488d1fca78ce8ad', {
  cluster: 'us2',
})

export class OrderStore {

  name = 'OrderStore'

  @observable isLoading = false
  @persist('object') @observable details = {}
  @observable error = null

  @computed
  get numSelectedProducts() {
    const out = this.details.products ?
      this.details.products
        .map(p => Number(p.quantity))
        .reduce(((total, amount) => total + amount), 0) : 0
    return out
  }

  @computed
  get subtotal() {
    const out = this.details.products ?
      this.details.products
        .filter(p => p.quantity > 0)
        .map(p => p.price * p.quantity)
        .reduce(((total, amount) => total + amount), 0) : 0
    return out
  }

  fetchOrder = flow(function* (orderId, onSuccess = () => {}) {
    this.isLoading = true
    this.error = null

    try {
      const response = yield getOrder(orderId)
      this.details = response.data
      this.isLoading = false
      onSuccess(response.data)
    } catch (error) {
      this.error = formatError(error)
      this.isLoading = false
    }
  })

  createOrder = flow(function* (payload, onSuccess = () => {}) {
    this.isLoading = true
    this.error = null

    try {
      const response = yield postOrder(payload)
      this.details = response.data
      this.isLoading = false
      onSuccess(response.data.orderID)
    } catch (error) {
      this.error = formatError(error)
      this.isLoading = false
    }
  })

  updateOrder = flow(function* (orderId, payload, onSuccess = () => {}) {
    this.isLoading = true
    this.error = null

    try {
      const response = yield putOrder(orderId, payload)
      this.details = response.data
      this.isLoading = false
      onSuccess()

    } catch (error) {
      this.error = formatError(error)
      this.isLoading = false
    }
  })

  @action
  subscribeToPusherEvents(orderId) {
    const channel = pusher.subscribe('lightning-pizza')
    channel.bind(orderId, () => {
      this.fetchOrder(orderId)
    })
  }

  @action.bound
  updateProducts(product, change) {
    const { products } = this.details
    const selectedIndex =
      products.findIndex(p => p.uuid === product.uuid)
    if (selectedIndex > -1) {
      if (products[selectedIndex].quantity === 1 && change === -1) {
        products.splice(selectedIndex, 1)
      } else {
        products[selectedIndex].quantity += change
      }
    } else {
      products.push(product)
    }
  }

  @action.bound
  addCustomPizza({ crust, toppings }) {
    this.updateProducts({
      category: 'custom',
      name: _startCase(`${crust.Name} ${toppings.map(t => t.Name).join(' ')} Pizza`),
      options: toppings.reduce((opts, t) => ({ ...opts, [t.key]: { '1/1': '1' } }), {}),
      price: '5.99',
      quantity: 1,
      uuid: uuidv1(),
      variant_code: crust.variant_code
    })
  }

  @action
  reset() {
    this.isLoading = false
    this.details = {}
    this.error = null
  }
}

export default new OrderStore()
