import _get from 'lodash/get'
import { inject, observer } from 'mobx-react'
import React from 'react'

import CouponForm from './CouponForm'
import Panel, { PanelHeader } from './Panel'
import Receipt from './Receipt'
import TextField from './TextField'
import SelectField from './SelectField'


@inject('orderStore', 'sessionStore', 'userFormStore')
@observer
class Contact extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      formSubmittedOnce: false,
      orderID: props.match.params.orderID,
      updatingTip: false,
      couponApplied: false,
      couponCode: ''
    }
  }

  componentDidMount() {
    if (this.props.orderStore.details.service === 'delivery') {
      this.props.userFormStore.setDelivery(true)
    }
  }

  handleTipChange = (id, value) => {
    const { orderStore, userFormStore } = this.props
    const { orderID } = this.state

    userFormStore.onFieldChange(id, value);
    orderStore.updateOrder(orderID, {
      [id]: value
    }, () => {
      this.setState({ updatingTip: false })
    })
    this.setState({ updatingTip: true })
  }

  purchase = (event) => {
    event.preventDefault();

    this.setState({formSubmittedOnce: true, couponApplied: false });

    const { userFormStore, orderStore, history } = this.props
    const { orderID } = this.state

    if (userFormStore.isValid) {
      orderStore.updateOrder(orderID, {
        ...orderStore.details,
        ...userFormStore.formData,
        status: 'built'
      }, () => {
        history.push(`/order/${orderID}`)
      })
    }
  }

  handleCouponCodeChange = (value) => {
    this.setState({
      couponCode: value,
      couponApplied: false
    })
  }

  applyCoupon = () => {
    const { orderStore } = this.props
    const { orderID, couponCode } = this.state

    this.setState({
      couponApplied: false
    })

    orderStore.updateOrder(orderID, {
      ...orderStore.details,
      coupon: couponCode
    }, () => {
      this.setState({
        couponApplied: true
      })
    })
  }

  isCouponError(error) {
    return error && error.code === 422 &&
      error.message.toLowerCase().indexOf('coupon') > -1
  }

  submitDisabled() {
    const { orderStore, userFormStore } = this.props

    if (!userFormStore.isValid) {
      return true
    }
    if (!orderStore.details.price_satoshis) {
      return true
    }
    if (orderStore.isLoading) {
      return true
    }
    if (orderStore.details.dominos_order_errors.length) {
      return true
    }
    if (this.state.formSubmittedOnce && !userFormStore.isValid) {
      return true
    }
    return false
  }

  render() {
    const { displayFields, onFieldChange } = this.props.userFormStore
    const { details: orderDetails, error, isLoading } = this.props.orderStore
    const { discountPercentage } = this.props.sessionStore

    const { updatingTip, couponApplied, couponCode } = this.state

    const orderErrors = _get(orderDetails, 'dominos_order_errors', [])

    return (
      <div className="contact">
        <div className="container page-container">
          <Panel className="finish-your-order">
            <PanelHeader onBackClick={this.props.history.goBack}>
              <h2>Finish Your Order</h2>
            </PanelHeader>
            <div className="cart">
              <div className="row">
                <div className="col-md">
                  {
                    Object.values(displayFields).map((field, idx) => {
                      if (field.type === "select") {
                        return <SelectField
                          key={idx}
                          id={field.id}
                          name={field.name}
                          error={field.error}
                          options={field.options}
                          values={field.value}
                          onChange={field.id === "tip_usd" ? this.handleTipChange : onFieldChange} />
                      } else {
                        return <TextField
                          key={idx}
                          id={field.id}
                          name={field.name}
                          error={field.error}
                          value={field.value}
                          maxLength={field.max_length}
                          optional={field.optional}
                          onChange={onFieldChange} />
                      }
                    })
                  }
                </div>
                <div className="col-md col-receipt">
                  <Receipt
                    orderDetails={orderDetails}
                    discountPercentage={orderDetails.discount_percentage || discountPercentage}
                    updatingTip={updatingTip} />
                  <CouponForm
                    onSubmit={this.applyCoupon}
                    onChange={this.handleCouponCodeChange}
                    coupon={couponCode}
                    error={this.isCouponError(error) ? error.message : null}
                    success={couponApplied}
                    isLoading={isLoading} />
                  {orderErrors.length > 0 &&
                    <>
                      <br></br>
                      <div>
                        <h4>Errors:</h4>
                        {orderErrors.map(function(err, idx){
                          return (<p key={idx}>{ err }</p>)
                        })}
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            <button onClick={this.purchase} type="submit" className="btn purchase-button" disabled={this.submitDisabled()}>Purchase</button>
            {
              error && error.message && !this.isCouponError(error) ? (
                <div className="error-message">
                  { error.message }
                </div>
              ) : ''
            }
            <div className="panel-footer">
              <div className="disclaimer">
                * Data Disclaimer: Lightning Pizza only collects the information necessary for Domino’s Pizza to fulfill your carryout and/or delivery orders. All information, including address, name, phone number and order details will be deleted after the order has been closed-out by Dominos. Emails, however, will be saved to send order updates and promotions.
              </div>
            </div>
          </Panel>
        </div>
      </div>
    )
  }
}

export default Contact
