import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'


const CRUSTS = [
  { Name: 'hand tossed', variant_code: '12SCREEN' },
  { Name: 'thin crust', variant_code: '12THIN' },
]


class Selectable extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    onUnselectableClick: PropTypes.func
  }

  static defaultProps = {
    onClick: () => {},
    selectable: true,
    selected: false,
    onUnselectableClick: () => {}
  }

  handleClick = (e) => {
    e.preventDefault()
    const { selectable, item, onClick, onUnselectableClick } = this.props

    if (!selectable) {
      onUnselectableClick()
    } else {
      onClick(item)
    }
  }

  render() {
    const { selected, item, element: Element } = this.props

    return (
      <Element className={classNames({ 'selected': selected })}
        onClick={this.handleClick}>
        {item.Name}
      </Element>
    )
  }
}


class CustomPizzaBuilder extends React.Component {
  static propTypes = {
    maxToppings: PropTypes.number,
    toppings: PropTypes.array,
    crusts: PropTypes.array,
    warningTimeout: PropTypes.number,
    onAddPizzaClick: PropTypes.func
  }

  static defaultProps = {
    maxToppings: 2,
    toppings: [],
    crusts: CRUSTS,
    warningTimeout: 820,
    onAddPizzaClick: () => {}
  }

  state = {
    isOpen: false,
    selectedCrust: CRUSTS[0],
    selectedToppings: [],
    warning: false
  }

  handleTitleClick = () => {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true
      })
    }
  }

  handleCancelClick = (e) => {
    e.preventDefault()
    this.setState({
      isOpen: false,
      selectedCrust: CRUSTS[0],
      selectedToppings: []
    })
  }

  handleCrustSelect = (crust) => {
    this.setState({
      selectedCrust: crust
    })
  }

  handleToppingSelect = (topping) => {
    const { selectedToppings } = this.state
    if (this.isToppingSelected(topping)) {
      this.setState({
        selectedToppings:
          selectedToppings.filter(item => item.key !== topping.key)
      })
    } else if (selectedToppings.length < this.props.maxToppings) {
      this.setState({
        selectedToppings: selectedToppings.concat([topping])
      })
    }
  }

  isToppingSelected = (topping) => {
    return !!this.state.selectedToppings.find(
        selectedTopping => selectedTopping.key === topping.key
      )
  }

  triggerWarning = () => {
    this.setState({
      warning: true
    })
    window.setTimeout(() => {
      this.setState({
        warning: false
      })
    }, this.props.warningTimeout)
  }

  handleAddPizza = (e) => {
    e.preventDefault()

    const { selectedCrust, selectedToppings } = this.state
    this.props.onAddPizzaClick({
      crust: selectedCrust,
      toppings: selectedToppings
    })

    this.setState({ isOpen: false }, () => {
      this.setState({
        selectedCrust: CRUSTS[0],
        selectedToppings: []
      })
    })
  }

  render() {
    const { isOpen, selectedCrust, selectedToppings, warning } = this.state
    const { maxToppings, toppings, crusts } = this.props

    return (
      <div className={classNames('custom-pizza-builder', { 'open': isOpen })}>
        <div className="custom-pizza-builder-header">
          <h2 onClick={this.handleTitleClick}>
            Build your own
          </h2>
          <button className="cancel-build-btn"
            onClick={this.handleCancelClick}>
            Cancel build
          </button>
        </div>
        <div className="crusts">
          <h3>Crust</h3>
          <div className="crust-buttons">
            {crusts.map((crust, i) => (
              <Selectable
                key={`crust-${i}`}
                element="button"
                item={crust}
                onClick={this.handleCrustSelect}
                selected={selectedCrust.Name === crust.Name} />
            ))}
          </div>
        </div>
        <div className="toppings">
          <h3>Toppings</h3>
          <div className="note">
            <small className={classNames({ 'warning': warning })}>
              2 item max
            </small>
          </div>
          <ul>
            {toppings.map(topping => (
              <Selectable
                key={`topping-${topping.key}`}
                element="li"
                item={topping}
                onClick={this.handleToppingSelect}
                selected={this.isToppingSelected(topping)}
                selectable={this.isToppingSelected(topping) ||
                  selectedToppings.length < maxToppings}
                onUnselectableClick={this.triggerWarning}/>
            ))}
          </ul>
        </div>
        <div className="custom-pizza-builder-footer">
          <button className="btn" onClick={this.handleAddPizza}>
            Add pizza
          </button>
        </div>
      </div>
    )
  }
}

export default CustomPizzaBuilder
