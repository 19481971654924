import _get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'

import { formatUSD } from '../utils'


const Receipt = ({ orderDetails, updatingTip, tipAsterisk, discountPercentage }) => {
  const service = _get(orderDetails, 'service', '')
  const net = _get(orderDetails, 'dominos_price_usd_breakdown.Net', 0)
  const surcharge = _get(orderDetails,
    'dominos_price_usd_breakdown.Surcharge', 0)
  const discount = _get(orderDetails,
    'dominos_price_usd_breakdown.Discount', 0)
  const tax = _get(orderDetails, 'dominos_price_usd_breakdown.Tax', 0)
  const total = orderDetails.order_total_usd ||
    Number(orderDetails.base_price_usd) + Number(orderDetails.tip_usd)

  return (
    <table className="receipt table-borderless">
      <tbody>
        <tr>
          <th scope="row">Service</th>
          <td>{ service.charAt(0).toUpperCase() + service.slice(1) }</td>
        </tr>
        {orderDetails.dominos_estimated_wait_minutes > 0 &&
          <tr>
            <th scope="row">Wait Time</th>
            <td>{ orderDetails.dominos_estimated_wait_minutes } minutes</td>
          </tr>
        }
        {net - surcharge > 0 &&
          <tr>
            <th scope="row">Food</th>
            <td>{ formatUSD(net - surcharge) }</td>
          </tr>
        }
        {discount > 0 &&
          <tr>
            <th scope="row">Discount</th>
            <td>{ formatUSD(discount) }</td>
          </tr>
        }
        {surcharge > 0 &&
          <tr>
            <th scope="row">Surcharge</th>
            <td>{ formatUSD(surcharge) }</td>
          </tr>
        }
        {tax > 0 &&
          <tr>
            <th scope="row">Tax</th>
            <td>{ formatUSD(tax) }</td>
          </tr>
        }
        {orderDetails.service === 'delivery' ? (
          <tr>
            <th scope="row">Tip</th>
            <td>{ updatingTip ? '...' : formatUSD(orderDetails.tip_usd) }{tipAsterisk ? '*' : ''}</td>
          </tr> ) : ''
        }
        {orderDetails.base_price_usd > 0 &&
          <tr>
            <th scope="row">Total</th>
            <td>
              {
                updatingTip ?
                  '...' :
                  <span>
                    <s>{ formatUSD(total) }</s>&nbsp;
                    <i>{discountPercentage}% off</i>
                  </span>
              }
            </td>
          </tr>
        }
        {orderDetails.price_usd > 0 &&
          <><tr>
            <th scope="row" className="text-uppercase">Your Price</th>
            <td className="font-weight-bold">{ updatingTip ? '...' : formatUSD(orderDetails.price_usd) }</td>
          </tr>
          <tr>
            <th scope="row" className="text-uppercase">You Pay</th>
            <td className="font-weight-bold">{ updatingTip ? '...' : orderDetails.price_satoshis.toLocaleString() } satoshis</td>
          </tr></>
        }
      </tbody>
    </table>
  )
}

Receipt.propTypes = {
  orderDetails: PropTypes.object,
  updatingTip: PropTypes.bool,
  discountPercentage: PropTypes.number,
}

Receipt.defaultProps = {
  orderDetails: {},
  updatingTip: false
}

export default Receipt
