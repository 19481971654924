import React, { Component } from 'react'
import Panel, { PanelHeader } from './Panel'

import SignUpForm from './SignUpForm'


class Downtime extends Component {


    render() {
        return (
            <div className="container page-container">
                <Panel className="downtime">
                    <PanelHeader showBackBtn={false} />
                    <form onSubmit={this.handleSubmit}>
                        <div className="downtime-content">
                            <div className="oh-no">
                                oh no,
                                <br />
                                looks like we're out of pizza.
                            </div>
                            <SignUpForm className="downtime-signup-form" />
                        </div>
                        <div className="contact-us">
                            <a href="mailto:support@ln.pizza">Contact Us</a>
                        </div>
                    </form>
                </Panel>
            </div>
        )
    }
}

export default Downtime
