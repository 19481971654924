import classNames from 'classnames'
import { inject, observer } from 'mobx-react';
import React from 'react'
import { Link } from 'react-router-dom'

import Panel, { PanelHeader } from './Panel'


@inject('locationsStore')
@inject('orderStore')
@observer
class StoreResults extends React.Component {
  state = {
    selectedStoreId: null
  }

  selectStore = (event) => {
    event.preventDefault()

    const { match, history, locationsStore, orderStore } = this.props

    const payload = {
      service: match.params.service,
      store_id: this.state.selectedStoreId,
      ...locationsStore.normalizedAddress
    }

    orderStore.createOrder(payload, (orderID) => {
      history.push(`/order/${orderID}/products`)
    })
  }

  handleRadioChange = (e) => {
    if (e.target.checked) {
      this.setState({
        selectedStoreId: e.target.value
      })
    }
  }

  isClosed(store) {
    const { service } = this.props.match.params
    const serviceKey = service.charAt(0).toUpperCase() + service.slice(1)

    return (store.IsOnlineNow === false ||
      store.ServiceIsOpen[serviceKey] === false)
  }

  renderLocations() {
    const { selectedStoreId } = this.state
    const { locations } = this.props.locationsStore
    const otherService = this.props.match.params.service === 'delivery' ?
      'carryout' : 'delivery'

    if (!locations.length) {
      return [
        <div className="panel-body" key="body">
          <p className="text-center">No results found.</p>
        </div>,
        <div className="panel-footer" key="footer">
          <Link className="btn" to={`/locations/${otherService}`}>
            Find {otherService} stores
          </Link>
        </div>
      ]
    } else {
      return (
        <div className="panel-body">
          {locations.map((loc, idx) => (
              <div className={classNames('form-check',
                { 'selected': selectedStoreId === loc.StoreID,
                'disabled': this.isClosed(loc) })}
                key={loc.StoreID}>
                <input className="form-check-input"
                  type="radio"
                  name={loc.StoreID}
                  id={loc.StoreID}
                  value={loc.StoreID}
                  onChange={this.handleRadioChange}
                  disabled={this.isClosed(loc)}
                  checked={selectedStoreId === loc.StoreID}/>
                <label className="form-check-label" htmlFor={loc.StoreID}>
                  <address>
                    <strong>Store #{loc.StoreID}</strong><br></br>
                    {loc.AddressDescription}
                  </address>
                </label>
              </div>
            ))}
        </div>
      )
    }
  }

  render() {
    const { selectedStoreId } = this.state
    const { locations } = this.props.locationsStore
    const { isLoading, error } = this.props.orderStore

    return (
      <div className="container page-container stores">
        <Panel className="select-store" sticky>
          <PanelHeader onBackClick={this.props.history.goBack}>
            <h2>Find your store</h2>
          </PanelHeader>
          { this.renderLocations() }
          {
            locations.length ? (
              <div className="panel-footer">
                <button
                  onClick={this.selectStore}
                  type="submit"
                  className="btn btn-secondary"
                  disabled={isLoading || !selectedStoreId}>
                  Select Location
                </button>
                { error && error.message &&
                  <div className="error-message">
                    { error.message }
                  </div>
                }
              </div>
            ) : ''
          }
        </Panel>
      </div>
    )
  }
}

export default StoreResults
