import { action, computed, toJS } from 'mobx'


class FormStore {
  isFieldValid(value, type, optional, maxLength) {
    if (optional && value.length === 0) {
      return true
    }

    switch (type) {
      case 'zipCode':
        return !isNaN(value) && value > 0 && value.length === 5
      case 'text':
        return value.length >= 1
      case 'text|maxLength':
        return value.length >= 1 && maxLength ? value.length < maxLength : true
      case 'email':
        return !! value.match(/.+@.+/)
      case 'phone':
        const phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/
        const digits = value.replace(/\D/g, "")
        return !! phoneRe.test(digits)
      default:
        return true
    }
  }

  getError(type, maxLength) {
    switch (type) {
      case 'zipCode':
        return 'Please provide a valid 5 digit US zipcode.'
      case 'text':
        return 'Please enter a value.'
      case 'text|maxLength':
        return `Please enter a value with a maximum of ${maxLength} characters.`
      case 'email':
        return 'Please enter a valid email address'
      case 'phone':
        return 'Please enter a phone number in the format 555-555-5555.'
      default:
        return 'Invalid value.'
    }
  }

  @action
  onFieldChange = (field, value) => {
    this.fields[field].value = value

    const type = this.fields[field].type
    const optional = this.fields[field].optional
    const maxLength = this.fields[field].max_length

    const isValid = this.isFieldValid(value, type, optional, maxLength)

    this.fields[field].error = isValid ? null : this.getError(type, maxLength)
  }

  @action
  setError = (errMsg) => {
    this.error = errMsg
  }

  @action
  reset() {
    Object.keys(this.fields).forEach(key => {
      this.fields[key].value = ''
      this.fields[key].error = null
    })

    this.isDelivery = false
    this.error = null
  }

  @computed
  get formData() {
    const fields = this.displayFields
    let data = {}
    Object.values(fields).forEach((field) => {
      data[field.id] = field.value
    })
    return data
  }

  @computed
  get isValid() {
    const displayFields = toJS(this.displayFields)
    return !Object.values(displayFields)
      .find(field => (!field.value && !field.optional) || field.error)
  }
}

export default FormStore

