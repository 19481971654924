import React from 'react'
import PropTypes from 'prop-types'


export const ArrowLeft = ({ fill, width, height, flip }) => (
    <svg viewBox="0 0 19 16" width={width} height={height}>
        <path transform={flip ? 'scale(-1, 1) translate(-19, 0)' : ''} d="M17.783 6.79H4.155l4.753-4.725a1.205 1.205 0 0 0 0-1.71A1.217 1.217 0 0 0 8.048 0c-.312 0-.624.118-.861.354l-6.83 6.79a1.202 1.202 0 0 0-.081.09l-.03.04c-.014.018-.029.036-.041.055-.012.016-.021.034-.031.05l-.03.051-.027.054c-.008.018-.017.035-.024.053-.008.018-.014.036-.02.054-.007.02-.015.038-.02.058-.006.018-.01.037-.015.055l-.015.06-.01.065-.007.052c-.008.08-.008.16 0 .239l.008.051c.003.022.005.044.01.066.003.02.009.04.014.059l.014.055.02.058.02.054.026.053c.008.018.016.037.026.054.009.017.02.034.03.05l.031.052.04.055.03.04.082.09 6.83 6.789a1.222 1.222 0 0 0 1.72 0 1.205 1.205 0 0 0 0-1.711L4.156 9.21h13.628C18.455 9.21 19 8.668 19 8c0-.668-.545-1.21-1.217-1.21z" fill={fill} fillRule="nonzero"/>
    </svg>

)

ArrowLeft.propTypes = {
    fill: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    flip: PropTypes.bool
}

ArrowLeft.defaultProps = {
    fill: '#878787',
    width: '19',
    height: '16',
    flip: false
}

export const Close = ({ fill, width, height }) => (
    <svg viewBox="0 0 13 13" width={width} height={height}>
        <path d="M12.633.362a1.269 1.269 0 0 0-1.766 0L6.5 4.673 2.133.362a1.269 1.269 0 0 0-1.766 0 1.23 1.23 0 0 0 0 1.744l4.366 4.312-4.366 4.311a1.23 1.23 0 0 0 0 1.744c.25.247.566.362.883.362.317 0 .633-.115.883-.362L6.5 8.162l4.367 4.311c.25.247.566.362.883.362.317 0 .633-.115.883-.362a1.23 1.23 0 0 0 0-1.744L8.267 6.418l4.366-4.312a1.23 1.23 0 0 0 0-1.744z" fill={fill} fillRule="nonzero"/>
    </svg>
)

Close.propTypes = {
    fill: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
}

Close.defaultProps = {
    fill: '#878787',
    width: '13',
    height: '13'
}

export const Facebook = ({ fill, width, height, backgroundFill }) => (
  <svg data-name="Layer 1" viewBox="0 0 100 100" width={width} height={height}>
    <title>{'flogo-RGB-HEX-Blk-100'}</title>
    <path
      d="M94.48 0h-89A5.52 5.52 0 0 0 0 5.52v89A5.52 5.52 0 0 0 5.52 100h47.9V61.33h-13V46.19h13V35.05c0-12.91 7.89-19.95 19.41-19.95a104.57 104.57 0 0 1 11.64.6v13.5h-7.94c-6.27 0-7.49 3-7.49 7.35v9.64H84l-2 15.14H69V100h25.48a5.52 5.52 0 0 0 5.52-5.52v-89A5.52 5.52 0 0 0 94.48 0z"
      fill={fill}
    />
    <path
      d="M69 100V61.33h13l2-15.14H69v-9.64c0-4.37 1.22-7.35 7.49-7.35h7.94V15.7a104.57 104.57 0 0 0-11.64-.6c-11.52 0-19.41 7-19.41 19.95v11.14h-13v15.14h13V100z"
      fill={backgroundFill}
    />
  </svg>
)

Facebook.propTypes = {
  backgroundFill: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

Facebook.defaultProps = {
  backgroundFill: '#f8e71c',
  fill: '#000',
  height: '100',
  width: '100'
}

export const Instagram = ({ fill, width, height }) => (
  <svg viewBox="0 0 503.84 503.84" width={width} height={height}>
    <title>{'instagram-logo'}</title>
    <path d="M251.92 45.39c67.27 0 75.23.26 101.8 1.47 24.56 1.12 37.9 5.22 46.78 8.67a78 78 0 0 1 29 18.85 78 78 0 0 1 18.85 29c3.45 8.88 7.55 22.22 8.67 46.78 1.21 26.57 1.47 34.53 1.47 101.8s-.26 75.23-1.47 101.8c-1.12 24.56-5.22 37.9-8.67 46.78a83.51 83.51 0 0 1-47.81 47.81c-8.88 3.45-22.22 7.55-46.78 8.67-26.56 1.21-34.53 1.47-101.8 1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67a78 78 0 0 1-29-18.85 78 78 0 0 1-18.85-29c-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.57-1.47-34.53-1.47-101.8s.26-75.23 1.47-101.8c1.12-24.56 5.22-37.9 8.67-46.78a78 78 0 0 1 18.85-29 78 78 0 0 1 29-18.85c8.88-3.45 22.22-7.55 46.78-8.67 26.57-1.21 34.53-1.47 101.8-1.47m0-45.39c-68.42 0-77 .29-103.87 1.52S102.92 7 86.92 13.22a123.68 123.68 0 0 0-44.64 29.06 123.68 123.68 0 0 0-29.06 44.64c-6.22 16-10.48 34.34-11.7 61.15S0 183.5 0 251.92s.29 77 1.52 103.87 5.48 45.13 11.7 61.13a123.68 123.68 0 0 0 29.06 44.62 123.52 123.52 0 0 0 44.64 29.07c16 6.23 34.34 10.49 61.15 11.71s35.45 1.52 103.87 1.52 77-.29 103.87-1.52 45.11-5.48 61.11-11.71a128.74 128.74 0 0 0 73.69-73.69c6.23-16 10.49-34.34 11.71-61.15s1.52-35.45 1.52-103.87-.29-77-1.52-103.87-5.48-45.11-11.71-61.11a123.52 123.52 0 0 0-29.05-44.62 123.68 123.68 0 0 0-44.64-29.08c-16-6.22-34.34-10.48-61.15-11.7S320.34 0 251.92 0z" />
    <path d="M251.92 122.56a129.36 129.36 0 1 0 129.36 129.36 129.35 129.35 0 0 0-129.36-129.36zm0 213.36a84 84 0 1 1 84-84 84 84 0 0 1-84 84z" />
    <circle cx={386.4} cy={117.44} r={30.23} />
  </svg>
)

Instagram.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

Instagram.defaultProps = {
  fill: '#000',
  height: '100',
  width: '100'
}

export const Lightning = ({ fill, width, height, flip }) => (
  <svg viewBox="0 0 9 17" width={width} height={height}>
    <path
      transform={flip ? 'scale(-1, 1) translate(-9, 0)' : ''}
      fill={fill}
      d="M5.666 0L0 10.06h3.887V17L9 7.829H5.666z"
      fillRule="evenodd"
    />
  </svg>
)

Lightning.propTypes = {
    fill: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    flip: PropTypes.bool
}

Lightning.defaultProps = {
    fill: '#FBE900',
    width: '9',
    height: '17',
    flip: false
}

export const LinkedIn = ({ fill, height, width }) => (
  <svg height={height} width={width} version="1.1" viewBox="0 0 27 26">
    <defs/>
    <g id="round-3" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g id="Final" fill={fill} transform="translate(-288.000000, -3868.000000)">
        <g id="Group-14" transform="translate(-128.000000, 3247.000000)">
          <path id="Linkedin" d="M416.341563,647 L422.127062,647 L422.127062,629.456973 L416.341563,629.456973 L416.341563,647 Z M419.235065,627.061917 C421.252842,627.061917 422.507746,625.715835 422.507746,624.031716 C422.473139,622.311217 421.252842,621 419.272682,621 C417.292521,621 416,622.311217 416,624.031716 C416,625.715835 417.254904,627.061917 419.195943,627.061917 L419.235065,627.061917 L419.235065,627.061917 Z M425.332033,647 C425.332033,647 425.408772,631.103195 425.332033,629.458489 L431.120542,629.458489 L431.120542,632.000583 L431.08142,632.000583 C431.842789,630.804571 433.213553,629.046175 436.335767,629.046175 C440.14261,629.046175 443,631.551889 443,636.942281 L443,647 L437.211491,647 L437.211491,637.615322 C437.211491,635.256646 436.373384,633.648321 434.278868,633.648321 C432.680896,633.648321 431.728433,634.73216 431.311636,635.781133 C431.155149,636.15555 431.120542,636.680037 431.120542,637.204524 L431.120542,647 L425.332033,647 L425.332033,647 Z"/>
        </g>
      </g>
    </g>
  </svg>
)

LinkedIn.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

LinkedIn.defaultProps = {
  fill: '#000',
  height: '26px',
  width: '27px'
}

export const Twitter = ({ fill, height, width }) => (
  <svg height={height} width={width} version="1.1" viewBox="0 0 30 24">
    <defs/>
    <g id="round-3" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g id="Final" fill={fill} transform="translate(-246.000000, -3870.000000)">
        <g id="Group-14" transform="translate(-128.000000, 3247.000000)">
          <path id="Twitter" d="M400.929734,628.977428 C400.940616,629.237582 400.946834,629.499267 400.946834,629.762482 C400.946834,637.769113 394.756555,647 383.436211,647 C379.960203,647 376.725153,645.997641 374,644.279092 C374.481915,644.334184 374.971603,644.36326 375.469064,644.36326 C378.352783,644.36326 381.006426,643.394567 383.112861,641.769368 C380.4188,641.720398 378.146025,639.968182 377.364079,637.56099 C377.738729,637.632915 378.124262,637.671173 378.520676,637.671173 C379.081874,637.671173 379.625972,637.596187 380.142087,637.458458 C377.32677,636.901422 375.206343,634.452911 375.206343,631.517758 L375.206343,631.441242 C376.03648,631.894217 376.984765,632.166614 377.993678,632.19875 C376.34273,631.112223 375.256089,629.257476 375.256089,627.156348 C375.256089,626.045336 375.559229,625.004718 376.089336,624.109482 C379.125402,627.774597 383.660068,630.18791 388.774588,630.440413 C388.670432,629.996621 388.616022,629.534464 388.616022,629.058535 C388.616022,625.713256 391.370712,623 394.770546,623 C396.541196,623 398.140844,623.736084 399.26324,624.91443 C400.665458,624.642033 401.982174,624.138558 403.171417,623.443793 C402.71282,624.857808 401.736553,626.045336 400.464919,626.795192 C401.710125,626.649812 402.896259,626.323854 404,625.841803 C403.174526,627.056877 402.131413,628.12351 400.929734,628.977428"/>
        </g>
      </g>
    </g>
  </svg>
)

Twitter.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string
}

Twitter.defaultProps = {
  fill: '#000',
  height: '24px',
  width: '30px'
}
