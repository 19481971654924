import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'


class ProductCard extends React.Component {
  handleClick = (e) => {
    const { product, onClick } = this.props
    const change = Number.parseInt(e.target.value)
    onClick(product, change)
    e.stopPropagation()
  }

  handleFirstClick = (e) => {
    const { product, onClick } = this.props

    if (product.quantity === 0) {
      onClick(product, 1)
    }
  }

  render() {
    const { product, disabledMinus, disabledPlus, custom,
      quantity } = this.props
    return (
      <div className={classNames('col-sm-6 col-md-4 col-lg-3 product-card',
        { 'custom': custom, 'selected': quantity > 0 })}
        key={ product.uuid }
        onClick={this.handleFirstClick} >
        <div className="card">
          { custom ? <div className="custom-label">Custom</div> : ''}
          <div className="card-body">
            <div className="card-body-top">
              <h5 className="card-title">{ product.name }</h5>
              <p className="card-text">${ product.price }</p>
            </div>
            <div className="card-body-bottom text-center">
              <div className="btn-group" role="group" aria-label="Quantity">
                <button
                  type="button"
                  name={product.uuid}
                  value={-1}
                  className="btn btn-secondary"
                  onClick={this.handleClick}
                  disabled={disabledMinus}>-</button>
                <div className="btn btn-secondary">{quantity}</div>
                <button
                  type="button"
                  value={1}
                  name={product.uuid}
                  className="btn btn-secondary"
                  onClick={this.handleClick}
                  disabled={disabledPlus}>+</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ProductCard.propTypes = {
  custom: PropTypes.bool,
  product: PropTypes.object,
  quantity: PropTypes.number,
  disabledMinus: PropTypes.bool,
  disabledPlus: PropTypes.bool,
  onClick: PropTypes.func
}

ProductCard.defaultProps = {
  custom: false
}

export default ProductCard
