import { action, computed, observable } from 'mobx'
import { persist } from 'mobx-persist'

import FormStore from './FormStore'


export class UserFormStore extends FormStore {

  name = 'UserFormStore'

  @persist
  @observable
  isDelivery = false

  @persist('object')
  @observable
  fields = {
    first_name: {
      id: 'first_name',
      name: 'First Name',
      type: 'text',
      value: '',
      error: null
    },
    last_name: {
      id: 'last_name',
      name: 'Last Name',
      type: 'text',
      value: '',
      error: null
    },
    email: {
      id: 'email',
      name: 'Email',
      type: 'email',
      value: '',
      error: null
    },
    phone: {
      id: 'phone',
      name: 'Phone',
      type: 'phone',
      value: '',
      error: null
    },
    tip_usd: {
      id: 'tip_usd',
      name: 'Tip',
      type: 'select',
      options: [
        { '0.00': '$0' },
        { '2.00': '$2' },
        { '4.00': '$4' },
        { '6.00': '$6' },
        { '8.00': '$8' },
      ],
      value: '0.00',
      error: null
    },
    delivery_instructions: {
      id: 'delivery_instructions',
      name: 'Delivery Notes',
      type: 'text|max_length',
      max_length: 70,
      optional: true
    }
  }

  @persist
  @observable
  error = null

  @computed
  get displayFields() {
    const carryOutFields = {
      ...this.fields
    }
    delete carryOutFields.tip_usd
    delete carryOutFields.delivery_instructions

    return this.isDelivery ? this.fields : carryOutFields
  }

  @action.bound
  setDelivery(val) {
    this.isDelivery = val
  }

  @action
  reset() {
    super.reset()

    // Tip needs an actual default
    this.fields.tip_usd.value = '0.00'
  }
}

export default new UserFormStore()
