import React from 'react'
import classNames from 'classnames'
import { CopyToClipboard } from 'react-copy-to-clipboard'



class CopyToClipboardButton extends React.Component {
  state = {
    copied: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOuterClick)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOuterClick)
  }

  setOuterRef = (node) => {
    this.outerRef = node
  }

  handleOuterClick = (e) => {
    if (this.outerRef && !this.outerRef.contains(e.target)) {
      this.setState({
        copied: false
      })
    }
  }

  handleCopy = () => {
    this.setState({
      copied: true
    })
  }

  render() {
    const { copyText, className } = this.props
    const { copied } = this.state

    return (
      <div ref={this.setOuterRef}
        className={classNames('copy-to-clipboard-btn', className)}>
        <CopyToClipboard text={copyText} onCopy={this.handleCopy}>
          <button className="btn" type="button">
            <span className="copy-text">{copyText}</span>
            <span className="copy">{ copied ? 'Copied!' : 'Copy'}</span>
          </button>
        </CopyToClipboard>
      </div>
    )
  }
}

export default CopyToClipboardButton
